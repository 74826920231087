import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Grid } from '@material-ui/core';
import Img from 'gatsby-image/withIEPolyfill';
import i18n from '../../../../locale';
import Typography from '../../../../components/Typography';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        background: imageSharp (fluid: { originalName: { eq: "About-Us-Hero.png" }}) {
          fluid(maxWidth: 2400, maxHeight: 700, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        hero: imageSharp (fluid: { originalName: { eq: "Fleet-Features-Hero.png" }}) {
          fluid(maxWidth: 960, maxHeight: 780, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ background, hero }) => (
      <Grid container justify='space-between'
        className={styles.container}>
        <Grid item className={styles.backgroundContainer}
          xs={12} sm={12} md={12}>
          <Img imgStyle={{ objectFit: 'cover' }}
            objectFit='cover'
            className={styles.imageContainer}
            fluid={background.fluid}
            alt={i18n('Fleet management solution for your vehicles')}/>
        </Grid>
        <Grid item className={styles.splashContainer}
          xs={12} sm={12} md={5} lg={5}>
          <Img imgStyle={{ objectFit: 'contain', maxHeight: '710px' }}
            objectFit='contain'
            className={styles.imageContainer}
            fluid={hero.fluid}
            alt={i18n('Fleet management solution for your vehicles')}/>
        </Grid>
        <Grid item container
          xs={12} sm={12} md={7} lg={7}
          alignContent='center'
          className={styles.overviewContainer}>
          <Typography variant='h1'
            color='dark'
            weight='regular'
            className={styles.title}>
            {i18n('A complete fleet management solution')}
          </Typography>
          <Typography variant='h5'
            weight='light'
            className={styles.footnote}>
            {i18n('Fleet is a simple to use fleet management solution for everyone within the company. Have a full overview of your fleet of vehicles, forget about paperwork and let your employees be more efficient.')}
          </Typography>
        </Grid>
      </Grid>
    )}/>
);