import React from "react";
import Content from '../views/Fleet/Features';

export default ({location}) => (
  <Content location={location} seo={{
    title: "Flådestyring til virksomheder i Danmark",
    lang: 'da-DK',
    description: 'Få bedre kendskab til Fleet flådestyring og de vigtigste funktioner, der hjælper dig med at optimere og forenkle dine daglige køretøjsopgaver.',
    meta: [{
      name: 'keywords',
      content: 'Flådestyring'
    }]
  }}/>
);